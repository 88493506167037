import {
  GET_ANALYTICS_FILTERS,
  GET_ANALYTICS_PASS,
  SIMULATE_WITH_DATA,
  GET_MATERIAL_GROUP_SETTING,
  GET_COMPLEMENTARY_INFO,
  BEGIN_SIMULATE_FETCH,
  FINISHED_SIMULATE_FETCH,
  SET_SIMUALTION_MATERIAL_DATA,
  SET_SIMULATION_QUANTITY_MODIFIER_ARRAY,
  SET_SIMULATION_MATERIAL_GROUP_SETTINGS,
  SET_SIMULATION_GREY_ZONE_ARRAY,
  SET_ADU_FREQ_UPD,
  SET_ORD_DAYS,
  PREVIOUS_SIM_DATA,
  GET_SAVED_SIMULATION_DATA,
  LOAD_SIMULATION_DATA,
  BEGIN_SIMULATION_ACTION,
  FINISHED_SIMULATION_ACTION,
  DELETE_QUANTITY_MODIFIER,
  MODIFY_GROUP_SETTING,
  SET_SIMULATION_DATE_RANGE,
  SET_SIMULATION_MODIFY_STATUS,
  SET_LOAD_SIMULATION_DATA,
  PREVIOUS_LOADED_SIM_INPUT_DATA,
  SET_LOADED_SIMULATION_DATA_STATUS,
  SET_LOADED_SIMULATION_DATA,
  SET_SIMULATE_WITH_DATA,
  GET_SIMULATION_STATUS,
  ANALYTICS_ALLOWED_STATUS,
  SET_TOGGLE_OPTIONS,
  SET_ANALYTICS_MATERIAL_DATA,
  BEGIN_ANALYZE,
  END_ANALYZE,
  QUERY_ANALYTICS_BY_MATERIAL_REPORT,
  QUERY_ANALYTICS_REPORT,
  QUERY_ANALYTICS_ALL_WORKSPACES_REPORT,
  GET_ANALYTICS_CURRENT_WORKSPACE_TIME_FILTER,
  GET_ANALYTICS_ALL_WORKSPACES_TIME_FILTER,
  SET_USER_SELECTED_ANALYTICS_TIME_FILTER,
  SET_AGGREGATE_TIME_FILTER,
  SET_SINGLE_ITEM_TIME_FILTER,
  SET_ALL_WORKSPACES_TIME_FILTER,
  GET_ANALYTICS_CURRENT_WORKSPACE_FIELD_FILTERS,
  GET_ANALYTICS_ALL_WORKSPACES_FIELD_FILTERS,
  SET_ANALYTICS_ALL_WORKSPACES_FIELD_FILTERS_PROPERTIES,
  SET_ANALYTICS_CURRENT_WORKSPACE_FIELD_FILTERS_PROPERTIES,
  SET_SELECTED_ALL_WORKSPACES_FIELD_FILTER_DATA,
  SET_SELECTED_CURRENT_WORKSPACE_FIELD_FILTER_DATA,
  BEGIN_FETCH_ANALYTICS_FILTERS,
  END_FETCH_ANALYTICS_FILTERS
} from "../actions/types";

import i18n from "../i18n";

import { WasAggregateSelected, WasCurrencyUnitsSelected, WasSingleWorkspaceSelected } from "../utils/constants";
import { CHART_OPTIONS_GLOBAL, TIME_FILTER_OPTIONS } from "../utils/constants";

const { CURRENT_WORKSPACE, SINGLE_ITEM, ALL_WORKSPACES } = TIME_FILTER_OPTIONS;

const initialState = {
  loadingAnalyticsChartData: true,
  loadingSimulation: false,
  filterSelections: {},
  filterOptions: {},
  buffered: true,
  chartOneOptions: CHART_OPTIONS_GLOBAL,
  chartOneData: {},
  chartOneHeight: 300,
  chartTwoData: {},
  chartTwoHeight: 300,
  analyticsPass : 'initial',
  simulationChartData: {},
  materialGroupSetting: {
    aduMeasurementStart: 0,
    aduMeasurementEnd: 90,
    variabilityFactor: 0.5,
    leadTimeFactor: 0.5,
  },
  simulationMaterialData:{name: "", data:""},
  simulationQuantityModifiers: [],
  simulationGroupSettings: {},
  simulationGreyZone:[],
  simulationAduFreqUpd: 1,
  simOrdDays: [0, 1, 2, 3, 4, 5, 6],
  complementaryInfo: {},
  previousSimData: {
    current_workspace_id: "", 
    material_id: "", 
    sim_dataset: [], 
    g_zone_input: [], 
    group_info: {}, 
    adu_freq: 1,
    ordering_days: []
  },
  simulationsSavedList: [],
  loadSimulationData: [],
  allowLoadAndModifySimulation: false,
  loadingSimulationActions: false,
  simulationDateRange: {startDateRange:"2021-01-01", endDateRange:"2021-10-01"},
  modifySimulationStatus: false,
  previousLoadedSimInputData: {},
  loadedSelectedSimulationData: {},
  isInputLoadedSimulationDataChange: true,
  materialDataList: [],
  disableAnalyticsContentInteraction: true,
  analyticsToggleOptions: { [WasAggregateSelected]: true , [WasCurrencyUnitsSelected]: true, [WasSingleWorkspaceSelected]: true}, 
  analyticsMaterialData: "",
  singleSkuChartData: {},
  analyticsByMaterialChartData: {},
  analyticsChartData: {},
  analyticsAllWorkspacesChartData: {},
  currentWorkspaceTimeFilter: [{text:i18n.t("analytics.noMonths"),value: 1}],
  allWorkspacesTimeFilter: [{text:i18n.t("analytics.noMonths"),value: 1}],
  byMaterialTimeFilter: [{text:i18n.t("analytics.noMonths"),value: 1}],
  analyticsUserSelectedTimeFilter: {[CURRENT_WORKSPACE]: {}, [ALL_WORKSPACES]: {}, [SINGLE_ITEM]: {}},
  currentWorkspaceFieldFilters: [],
  allWorkspacesFieldFilters: [],
  selectedCurrentWorkspaceFieldFilter : {},
  selectedAllWorkspacesFiedFilter : {},
  loadingAnalyticsFilters: false
};


export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case QUERY_ANALYTICS_BY_MATERIAL_REPORT:
      return{
        ...state,
        analyticsByMaterialChartData: payload
      }
    case QUERY_ANALYTICS_REPORT:
      return{
        ...state,
        analyticsChartData: payload
      }
    case QUERY_ANALYTICS_ALL_WORKSPACES_REPORT:
      return{
        ...state,
        analyticsAllWorkspacesChartData: payload
      }
    case GET_ANALYTICS_FILTERS:
      return {
        ...state,
        filterOptions: payload,
      };
    case GET_ANALYTICS_PASS:
      return {
        ...state,
        analyticsPass: payload,
      }
    case BEGIN_SIMULATE_FETCH:
      return{
        ...state,
        loadingSimulation: true
      }
    case SIMULATE_WITH_DATA:
      return {
        ...state,
        simulationChartData: typeof payload === 'object' ? payload : {},
      }
    case FINISHED_SIMULATE_FETCH:
        return{
          ...state,
          loadingSimulation: false
        }
    case GET_MATERIAL_GROUP_SETTING:
      return{
        ...state,
        //TODO: refactor this if is possible
        materialGroupSetting: {
          aduMeasurementStart: payload.adu_measurement_start ? payload.adu_measurement_start : 0,
          aduMeasurementEnd: payload.adu_measurement_end ? payload.adu_measurement_end : 90,
          variabilityFactor: payload.variability_factor ?  payload.variability_factor : 0.5 ,
          leadTimeFactor: payload.lead_time_factor ? payload.lead_time_factor : 0.5,
        },
      }
    case GET_COMPLEMENTARY_INFO:
      return{
        ...state,
        complementaryInfo: payload
      }
    case SET_SIMUALTION_MATERIAL_DATA:
      return{
        ...state,
        simulationMaterialData: payload
      }
    case SET_SIMULATION_QUANTITY_MODIFIER_ARRAY:
      return{
        ...state,
        simulationQuantityModifiers: payload
      }
    case SET_SIMULATION_MATERIAL_GROUP_SETTINGS:
      return{
        ...state,
        simulationGroupSettings: payload
      }
    case SET_SIMULATION_GREY_ZONE_ARRAY:
      return{
        ...state,
        simulationGreyZone:payload
      }
    case SET_ADU_FREQ_UPD:
      return{
        ...state,
        simulationAduFreqUpd: payload
      }
      case SET_ORD_DAYS:
        return{ 
          ...state,
          simOrdDays: payload
        }
    case PREVIOUS_SIM_DATA:
      return{
        ...state, 
        previousSimData: payload
      }
    case GET_SAVED_SIMULATION_DATA:
      return{
        ...state,
        simulationsSavedList: payload
      }
    case LOAD_SIMULATION_DATA:
      return{
        ...state,
        loadSimulationData: payload
      }
    case BEGIN_SIMULATION_ACTION:
      return{
        ...state,
        loadingSimulationActions: true
      }
    case FINISHED_SIMULATION_ACTION:
      return{
        ...state,
        loadingSimulationActions: false
      }
    case DELETE_QUANTITY_MODIFIER:
      return{
        ...state,
        simulationQuantityModifiers: payload
      }
    case MODIFY_GROUP_SETTING:
      return{
        ...state,
        simulationGroupSettings: payload
      }
    case SET_SIMULATION_DATE_RANGE:
      return{
        ...state,
        simulationDateRange: payload
      }
    case SET_SIMULATION_MODIFY_STATUS:
      return{
        state,
        modifySimulationStatus: payload
      }
    case SET_LOAD_SIMULATION_DATA:
      return{
        ...state,
        loadSimulationData: payload
      }
    case PREVIOUS_LOADED_SIM_INPUT_DATA:
      return{
        ...state,
        previousLoadedSimInputData: payload
      }
    case SET_LOADED_SIMULATION_DATA_STATUS:
      return{
        ...state,
        isInputLoadedSimulationDataChange: payload
      }
    case SET_LOADED_SIMULATION_DATA:
      return{
        ...state,
        loadedSelectedSimulationData: payload
      }
    case SET_SIMULATE_WITH_DATA:
      return{
        ...state,
        simulationChartData: typeof payload === 'object' ? payload : {},
      }
    case GET_SIMULATION_STATUS:
      return{
        ...state,
        materialDataList: payload
      }
    case ANALYTICS_ALLOWED_STATUS:
      return{
        ...state,
        disableAnalyticsContentInteraction: payload
      }
    case SET_TOGGLE_OPTIONS:
      return{
        ...state,
        analyticsToggleOptions: payload
      }
    case SET_ANALYTICS_MATERIAL_DATA:
      return{
        ...state,
        analyticsMaterialData: payload
      }
    case BEGIN_ANALYZE:
      return{
        ...state,
        loadingAnalyticsChartData: true
      }
    case END_ANALYZE:
      return{
        ...state,
        loadingAnalyticsChartData: false
      }
    case BEGIN_FETCH_ANALYTICS_FILTERS:
      return{
        ...state,
        loadingAnalyticsFilters: true
      }
    case END_FETCH_ANALYTICS_FILTERS:
      return{
        ...state,
        loadingAnalyticsFilters: false
      }
    case GET_ANALYTICS_CURRENT_WORKSPACE_TIME_FILTER:
      return{
        ...state,
        currentWorkspaceTimeFilter: payload,
        byMaterialTimeFilter: payload,
      }
    case GET_ANALYTICS_ALL_WORKSPACES_TIME_FILTER:
      return{
        ...state,
        allWorkspacesTimeFilter: payload,
      }
    case SET_USER_SELECTED_ANALYTICS_TIME_FILTER:
      return{
        ...state,
        analyticsUserSelectedTimeFilter: payload
      }
    case SET_AGGREGATE_TIME_FILTER:
      return{
        ...state,
        currentWorkspaceTimeFilter: payload
      }
    case SET_SINGLE_ITEM_TIME_FILTER:
      return{
        ...state,
        byMaterialTimeFilter: payload
      }
    case SET_ALL_WORKSPACES_TIME_FILTER:
      return{
        ...state,
        allWorkspacesTimeFilter: payload
      }
    case GET_ANALYTICS_CURRENT_WORKSPACE_FIELD_FILTERS:
      return{
        ...state,
        currentWorkspaceFieldFilters: payload
      }
    case GET_ANALYTICS_ALL_WORKSPACES_FIELD_FILTERS:
      return{
        ...state,
        allWorkspacesFieldFilters: payload
      }
    case SET_ANALYTICS_ALL_WORKSPACES_FIELD_FILTERS_PROPERTIES:
      return{
        ...state,
        allWorkspacesFieldFilters: payload
      }
    case SET_ANALYTICS_CURRENT_WORKSPACE_FIELD_FILTERS_PROPERTIES:
      return{
        ...state,
        currentWorkspaceFieldFilters: payload
      }
    case SET_SELECTED_ALL_WORKSPACES_FIELD_FILTER_DATA:
      return{
        ...state,
        selectedAllWorkspacesFiedFilter: payload
      }
    case SET_SELECTED_CURRENT_WORKSPACE_FIELD_FILTER_DATA:
      return{
        ...state,
        selectedCurrentWorkspaceFieldFilter: payload
      }
    default:
      return state;
  }
}