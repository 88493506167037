import React, { useState, useEffect } from 'react'
import { Grid, Segment, Form, Button, Input } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { changeSimulationQuantityModifier, changeLoadSimulationData } from '../../../../actions/analytics-v2'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { maskTextToExtractFloat } from '../../../../utils/format'
import "./QuantityModifier.css"
import useCurrentDateRange from '../../../../hooks/useCurrentDateRange'
import classNames from 'classnames'
import _ from "lodash"
import { allowAddQuantityModifier } from './validationForm'

const QuantityModifier = 
({ 
    modalData = {},
    closeModal,
    changeSimulationQuantityModifier, 
    simulationQuantityModifiers, 
    dateRange, 
    disabled,
    loadSimulationData,
    changeLoadSimulationData,
})=>{
    const { t } = useTranslation()
    //TODO: improve this hook, because in future could be a problem
    const [ action, setAction ] = useState(false)
    const [ dateAndMultiplier, setDateAndMultiplier ] = useState({  start_date: "", 
                                                                    end_date: "",  
                                                                    multiplier: ""})
    const [ errorFields, setErrorFields] = useState({   startDate: false, 
                                                        endDate: false, 
                                                        multiplier: false})

    const { currentDateRange, isStartDateSelected } = useCurrentDateRange(dateAndMultiplier.start_date, dateRange)
    const [ isModalData, setIsModalData ] = useState(false)
    const { loaded_results="", sim_inputs="" } = loadSimulationData[0] || {}

    useEffect(()=>{
        if("multiplier" in modalData){
            const newModelData = {  
                    start_date: modalData.start_date, 
                    end_date: modalData.end_date,  
                    multiplier: modalData.multiplier
            }
            setDateAndMultiplier(newModelData)
            setIsModalData(true)
        }
    }, [modalData])

    // Helper function to convert date from DD/MM/YYYY format to YYYY-MM-DD for HTML date inputs
    const formatDateForInput = (dateStr) => {
        if (!dateStr) return "";
        // If date is already in YYYY-MM-DD format, return as is
        if (dateStr.includes('-')) return dateStr;
        // Convert from DD/MM/YYYY to YYYY-MM-DD
        return dateStr.split('/').reverse().join('-');
    }

    // Helper function to convert date from YYYY-MM-DD format to DD/MM/YYYY for internal storage
    const formatDateForStorage = (dateStr) => {
        if (!dateStr) return "";
        // Convert from YYYY-MM-DD to DD/MM/YYYY
        return dateStr.split('-').reverse().join('/');
    }

                                        
    const addQuantityModifier = ()=>{
        const allow = allowAddQuantityModifier(dateAndMultiplier, setErrorFields, setDateAndMultiplier, setAction, action)
        if(!modalData["multiplier"] && allow && !loadSimulationData.length){
            changeSimulationQuantityModifier([...simulationQuantityModifiers, dateAndMultiplier])
            return 
        }
        if(allow && loadSimulationData.length){
            changeLoadSimulationData([{ loaded_results,
                                        sim_inputs:{...sim_inputs, 
                                                    sim_dataset: [...sim_inputs.sim_dataset, dateAndMultiplier]}}])
        }
    }

    const filterData = (typeArray)=>{
        return typeArray
                        .map((simQtyObj)=>
                                            _.isEqual(simQtyObj, modalData)
                                                ? dateAndMultiplier
                                                : simQtyObj
                                        )
    }
    

    const changeQuantityModifierValue = ()=>{
        const allow = allowAddQuantityModifier(dateAndMultiplier, setErrorFields, setDateAndMultiplier, setAction, action)
        
        if(modalData["multiplier"] && allow && simulationQuantityModifiers.length){
            const newSimulationQuantityModifiers = filterData(simulationQuantityModifiers)
            changeSimulationQuantityModifier(newSimulationQuantityModifiers)
            closeModal()
        }
        if(modalData["multiplier"] && allow && loadSimulationData.length){
            const simulationQuantityModifiers = sim_inputs.sim_dataset
            const newQuantityModifierArray = filterData(simulationQuantityModifiers)
            changeLoadSimulationData([{ loaded_results, 
                                        sim_inputs: {...sim_inputs, sim_dataset: newQuantityModifierArray} }])
            closeModal()
        }
    }

    const inputMultiplierValue = dateAndMultiplier.multiplier ? maskTextToExtractFloat(String(dateAndMultiplier.multiplier)) : ""

    // Convert dateRange to format suitable for date inputs
    const minDate = formatDateForInput(dateRange.startDateRange);
    const maxDate = formatDateForInput(dateRange.endDateRange);

    return(
    <Grid.Column mobile={8} widescreen={11} tablet={11}>
        <Segment  disabled={disabled} basic={isModalData}>
            {
                !isModalData    &&  <Grid.Row>
                                        <label 
                                            className="segment-title">
                                            {t("simulation.quantityModifiers")}
                                        </label>
                                    </Grid.Row>
                
            }
            <Grid columns={3} stackable className={classNames({'grid-quantity-modifier': isModalData})}>
            <Grid.Column>
                <Form.Field required error={errorFields.startDate} disabled={disabled}>
                    <label className="field-title">{t("simulation.startDate")}</label>
                    <Input
                        type="date"
                        value={formatDateForInput(dateAndMultiplier.start_date)}
                        min={minDate}
                        max={maxDate}
                        onChange={(e) => {
                            setDateAndMultiplier({
                                ...dateAndMultiplier, 
                                start_date: formatDateForStorage(e.target.value)
                            });
                        }}
                        disabled={disabled}
                        fluid
                        className="date-input"
                    />
                </Form.Field>
            </Grid.Column>
            <Grid.Column>
                <Form.Field required error={errorFields.endDate} disabled={disabled || isStartDateSelected}>
                    <label className="field-title">{t("simulation.endDate")}</label>
                    <Input
                        type="date"
                        value={formatDateForInput(dateAndMultiplier.end_date)}
                        min={formatDateForInput(currentDateRange.startDateRange)}
                        max={formatDateForInput(currentDateRange.endDateRange)}
                        onChange={(e) => {
                            setDateAndMultiplier({
                                ...dateAndMultiplier, 
                                end_date: formatDateForStorage(e.target.value)
                            });
                        }}
                        disabled={disabled || isStartDateSelected}
                        fluid
                        className="date-input"
                    />
                </Form.Field>
            </Grid.Column>
            <Grid.Column>
                <Form.Field error={errorFields.multiplier} required disabled={disabled}>
                    <label 
                        className="field-title">
                            {t("simulation.quantityMultiplier")}
                    </label>
                    <Input
                        disabled={disabled}
                        className="multiplier-label" 
                        value={inputMultiplierValue}
                        placeholder={!dateAndMultiplier.multiplier ? "0.009" : null}
                        fluid
                        onChange={(event, valueInserted)=>{setDateAndMultiplier({...dateAndMultiplier, 
                                                            multiplier: valueInserted.value})}}
                        error={errorFields.multiplier}
                        />
                    {
                       isModalData === false
                            ?   <Button 
                                    fluid 
                                    className="button-add"
                                    disabled={disabled}
                                    onClick={(event)=>{ event.preventDefault(); addQuantityModifier()}}
                                    >
                                    {t("simulation.addTittle")}
                                </Button> 
                            : null
                    }
                </Form.Field>
            </Grid.Column>
            </Grid>
            {
                isModalData     &&  <div className={classNames({'action-container': isModalData})}>
                                        <Grid>
                                            <Grid.Row className='action-quantity-modifier-section'>
                                                <Button 
                                                    positive
                                                    onClick={(event)=>{event.preventDefault(); changeQuantityModifierValue();}}>
                                                    {t("confirm")}
                                                </Button>
                                                
                                                <Button 
                                                    color='black'
                                                    onClick={()=>closeModal()}
                                                    >
                                                    {t("cancel")}
                                                </Button>
                                            </Grid.Row>
                                        </Grid> 
                                    </div>
            }
        </Segment>
    </Grid.Column>
    )
}

QuantityModifier.propTypes = {
    modalData: PropTypes.object,
    closeModal: PropTypes.func,
    changeSimulationQuantityModifier: PropTypes.func,
    simulationQuantityModifiers: PropTypes.array,
    dateRange: PropTypes.object,
    disabled: PropTypes.bool,
    loadSimulationData: PropTypes.array,
    changeLoadSimulationData: PropTypes.func,
}

const mapStateToProps = (state)=>({
    simulationQuantityModifiers: state.analytics.simulationQuantityModifiers,
    loadSimulationData: state.analytics.loadSimulationData,
})

export default connect( mapStateToProps,
                        {   changeSimulationQuantityModifier, 
                            changeLoadSimulationData, 
                             })(QuantityModifier)