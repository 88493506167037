import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Dropdown, Grid } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import "./WorkspaceDropdown.css";

const WorkspaceDropdown = ({
  workspaces,
  selectedWorkspaces,
  onChange,
  disabled
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [checkedWorkspaces, setCheckedWorkspaces] = useState({});

  // Initialize the checked state based on selectedWorkspaces
  useEffect(() => {
    if (selectedWorkspaces && workspaces) {
      const initialChecked = {};
      workspaces.forEach(workspace => {
        initialChecked[workspace.id] = selectedWorkspaces.includes(workspace.id);
      });
      setCheckedWorkspaces(initialChecked);
    }
  }, [selectedWorkspaces, workspaces]);

  const updateChecked = (workspaceId, value) => {
    setCheckedWorkspaces({ ...checkedWorkspaces, [workspaceId]: value });
  };

  const handleWorkspaceChange = (workspaceId, checked) => {
    const updatedSelectedWorkspaces = checked 
      ? [...selectedWorkspaces, workspaceId]
      : selectedWorkspaces.filter(id => id !== workspaceId);
    
    onChange(updatedSelectedWorkspaces);
  };

  const handleSelectAll = (selectAll) => {
    // Create new state with all workspaces checked or unchecked
    const newCheckedState = {};
    workspaces.forEach(workspace => {
      newCheckedState[workspace.id] = selectAll;
    });
    setCheckedWorkspaces(newCheckedState);
    
    // Update the selectedWorkspaces list
    const newSelectedWorkspaces = selectAll 
      ? workspaces.map(workspace => workspace.id)
      : [];
    onChange(newSelectedWorkspaces);
  };

  const areAllSelected = workspaces.length > 0 && 
    workspaces.every(workspace => checkedWorkspaces[workspace.id]);

  return (
    <Dropdown
      className="ui selection dropdown"
      upward={false}
      disabled={disabled}
      placeholder={
        _.isEmpty(workspaces)
          ? t("analytics.noWorkspaces")
          : t("analytics.workspaces")
      }
      onClick={() => setOpen(true)}
      onBlur={() => {
        setOpen(false);
      }}
      closeOnChange={false}
      open={open}
    >
      {_.isEmpty(workspaces) ? null : (
        <Dropdown.Menu className="workspace-dropdown">
          <Dropdown.Item className="workspace-dropdown-item select-all-item">
            <Grid>
              <Grid.Row>
                <div className="ui item checkbox">
                  <input
                    type="checkbox"
                    name="selectAll"
                    onChange={(e) => handleSelectAll(e.target.checked)}
                    checked={areAllSelected}
                  />
                  <label>{areAllSelected ? t("groupSettings.unselectAll", "Deselect All") : t("groupSettings.selectAll", "Select All")}</label>
                </div>
              </Grid.Row>
            </Grid>
          </Dropdown.Item>
          <Dropdown.Divider />
          {workspaces.map(workspace => (
            <Dropdown.Item
              key={workspace.id}
              className="workspace-dropdown-item"
            >
              <Grid>
                <Grid.Row>
                  <div className="ui item checkbox">
                    <input
                      type="checkbox"
                      name={workspace.name}
                      onChange={e => {
                        const isChecked = e.target.checked;
                        updateChecked(workspace.id, isChecked);
                        handleWorkspaceChange(workspace.id, isChecked);
                      }}
                      checked={checkedWorkspaces[workspace.id] || false}
                    />
                    <label>{workspace.name}</label>
                  </div>
                </Grid.Row>
              </Grid>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

WorkspaceDropdown.propTypes = {
  workspaces: PropTypes.arrayOf(PropTypes.object),
  selectedWorkspaces: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

WorkspaceDropdown.defaultProps = {
  workspaces: [],
  selectedWorkspaces: [],
  onChange: _.noop,
  disabled: false
};

export default WorkspaceDropdown; 