import React, { useState, useEffect, useMemo } from 'react'
import { Modal, Button, Grid, Radio, Form, Input } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import './ModalGreyZone.css'
import { connect } from 'react-redux'
import { maskTextToExtractFloat } from '../../../../utils/format'
import classNames from 'classnames'
import { allowAddGreyZone } from './validationForm'
import useCurrentDateRange from '../../../../hooks/useCurrentDateRange'
import { changeLoadSimulationData } from '../../../../actions/analytics-v2'
import _ from "lodash"

const ModalGreyZone = 
({  
    modalData = {},
    disabled, 
    changeGreyZone, 
    dateRange, 
    simulationGreyZone,
    changeLoadSimulationData,
    loadSimulationData 
})=>{
    const { t } = useTranslation()
    const [ open, setOpen ] = useState(false)
    const [ datesData, setDatesData ] = useState({startDate: "", endDate: ""})
    const [ disableQtyPerDay, setDisableQtyPerDay ] = useState(false)
    const [ qtyPerDay, setQtyPerDay ] = useState(null)
    const [ adusPerDay, setAdusPerDay ] = useState(null)
    const [ errorFields, setErrorFields ] = useState({startDate: false,
                                                        endDate: false,
                                                        adusPerDay: false,
                                                        qtyPerDay: false})

    const [ dataToSend, setDataToSend ] = useState(simulationGreyZone)
    const [ onAction, setOnAction ] = useState(false)
    const { loaded_results="", sim_inputs="" } = loadSimulationData[0] || {}

    const existModalData = useMemo(()=>
        ("start_date" in modalData ? true : false), [modalData])

    const reply = useMemo(()=>
            ({ 
            start_date: datesData.startDate,
            end_date: datesData.endDate,
            adus_per_day: disableQtyPerDay ? parseFloat(adusPerDay) : null,
            quantity_per_day: !disableQtyPerDay ? parseFloat(qtyPerDay) : null
            }), [datesData, adusPerDay, qtyPerDay, disableQtyPerDay])
    
    const { currentDateRange, isStartDateSelected } = useCurrentDateRange(datesData.startDate, dateRange)

    useEffect(()=>{
        setDataToSend(simulationGreyZone)
    },[simulationGreyZone])

    useEffect(()=>{
        if("start_date" in modalData){
            const newDateData = {
                startDate: modalData.start_date,
                endDate: modalData.end_date,
            }
            setDatesData(newDateData)
            setQtyPerDay(modalData.quantity_per_day)
            setAdusPerDay(modalData.adus_per_day)
            if(modalData.quantity_per_day){
                setDisableQtyPerDay(false)
            }
        }
    }, [ modalData, open ])

    // Helper function to convert date from DD/MM/YYYY format to YYYY-MM-DD for HTML date inputs
    const formatDateForInput = (dateStr) => {
        if (!dateStr) return "";
        // If date is already in YYYY-MM-DD format, return as is
        if (dateStr.includes('-')) return dateStr;
        // Convert from DD/MM/YYYY to YYYY-MM-DD
        return dateStr.split('/').reverse().join('-');
    }

    // Helper function to convert date from YYYY-MM-DD format to DD/MM/YYYY for internal storage
    const formatDateForStorage = (dateStr) => {
        if (!dateStr) return "";
        // Convert from YYYY-MM-DD to DD/MM/YYYY
        return dateStr.split('-').reverse().join('/');
    }

    const addGreyZone = ()=>{
        const allowAdd = allowAddGreyZone(reply, adusPerDay, qtyPerDay, datesData, disableQtyPerDay, setErrorFields)
        if(allowAdd){
            setDatesData({startDate: "", endDate: ""})
            setQtyPerDay(null)
            setAdusPerDay(null)
            setOnAction(onAction ? false : true)
            setDataToSend((dataToSend)=>[...dataToSend, reply])
        }
    }

    const filterData = (typeArray)=>{
        return typeArray
                    .map((greyZoneObject)=> 
                        _.isEqual(greyZoneObject, modalData) ? reply : greyZoneObject)
    }

    const resetValues = ()=>{
        setErrorFields({
            startDate: false,
            endDate: false,
            adusPerDay: false,
            qtyPerDay: false
        })
        setOpen(false)
        return
    }

    const newSimulationGreyZone = ()=>{
        const allowAdd = allowAddGreyZone(reply, adusPerDay, qtyPerDay, datesData, disableQtyPerDay, setErrorFields)
        if(allowAdd && simulationGreyZone.length){
            const newSimulationGreyZone = filterData(simulationGreyZone)
            changeGreyZone(newSimulationGreyZone)
            resetValues()
        }
        if(allowAdd && loadSimulationData.length){
            const newTypeArray = sim_inputs.g_zone_input
            const newSimulationGreyZone = filterData(newTypeArray)
            changeLoadSimulationData([{loaded_results, 
                                        sim_inputs: {...sim_inputs,
                                                    g_zone_input: newSimulationGreyZone }}])
            resetValues()
        }
    }


    const sendData = ()=>{
        if(existModalData){
            newSimulationGreyZone()
        }

        if(existModalData===false && loadSimulationData.length){
            const newGreyZoneArray = sim_inputs.g_zone_input.concat(dataToSend)
            changeLoadSimulationData([{loaded_results, 
                                        sim_inputs: {...sim_inputs,
                                                    g_zone_input: newGreyZoneArray }}])
            setDataToSend([])
            resetValues()
        }

        if(existModalData===false && !loadSimulationData.length){
            changeGreyZone(dataToSend)
            setDataToSend([])
            setOpen(false)
        }
        
    }

    const cancelButton = ()=>{
        setDataToSend([])
        setErrorFields({
            startDate: false,
            endDate: false,
            adusPerDay: false,
            qtyPerDay: false
        })
        setDatesData({startDate: "", endDate: ""})
        setQtyPerDay(null)
        setAdusPerDay(null)
        setOpen(false)
    }

    let disableConfirmButton = false
    if(!existModalData){
        disableConfirmButton = dataToSend.length>simulationGreyZone.length ? false: true
    }
    
    const disableAddButton = (datesData.startDate && datesData.endDate) || qtyPerDay || adusPerDay ? false : true

    // Convert dateRange to format suitable for date inputs
    const minDate = formatDateForInput(dateRange.startDateRange);
    const maxDate = formatDateForInput(dateRange.endDateRange);

    return(
        <Modal
            onClose={()=>setOpen(false)}
            onOpen={()=>setOpen(true)}
            open={open}
            trigger={
                <Button 
                    fluid={!existModalData} 
                    disabled={disabled} 
                    positive={existModalData}
                    icon={existModalData ? "pencil" : null}
                    className={classNames({"button-color": !existModalData})}>
                        {existModalData ? null : t("simulation.addGreyZones")}
                </Button>}
            >
                <Modal.Header>{t("simulation.addGreyZones")}</Modal.Header>
                <Modal.Content>
                    <Grid stackable>
                    <Grid.Row columns={2}>
                    <Grid.Column>
                        <Form>
                        <Form.Field required error={errorFields.startDate}>
                            <label className="field-title">{t("simulation.startDate")}</label>
                            <Input
                                type="date"
                                value={formatDateForInput(datesData.startDate)}
                                min={minDate}
                                max={maxDate}
                                onChange={(e) => {
                                    setDatesData({
                                        ...datesData, 
                                        startDate: formatDateForStorage(e.target.value)
                                    });
                                }}
                                fluid
                                className="date-input"
                            />
                        </Form.Field>
                        </Form>
                    </Grid.Column>
                    <Grid.Column>
                        <Form>
                        <Form.Field required error={errorFields.endDate} disabled={isStartDateSelected}>
                            <label className="field-title">{t("simulation.endDate")}</label>
                            <Input
                                type="date"
                                value={formatDateForInput(datesData.endDate)}
                                min={formatDateForInput(currentDateRange.startDateRange)}
                                max={formatDateForInput(currentDateRange.endDateRange)}
                                onChange={(e) => {
                                    setDatesData({
                                        ...datesData, 
                                        endDate: formatDateForStorage(e.target.value)
                                    });
                                }}
                                disabled={isStartDateSelected}
                                fluid
                                className="date-input"
                            />
                        </Form.Field>
                        </Form>
                    </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                    <Grid.Column>
                        <Form>
                        <Form.Field error={errorFields.qtyPerDay}>
                        <Radio
                            className="per-day-radio"
                            label={t("detailsPanel.numericalDetails.onHandStock.quantity")}
                            name="per-day-radio-group"
                            checked={!disableQtyPerDay}
                            onChange={() => setDisableQtyPerDay(false)}
                        />
                        </Form.Field>
                        
                        <Form.Field
                        required={true}
                        error={errorFields.qtyPerDay}
                        >
                        <Input
                            fluid
                            className="per-day-input"
                            name="adusPerDay"
                            onChange={(e) => setQtyPerDay(maskTextToExtractFloat(e.target.value))}
                            disabled={disableQtyPerDay}
                            placeholder={qtyPerDay===null ? "0.30":qtyPerDay} 
                            error={errorFields.qtyPerDay}
                            value={qtyPerDay ? qtyPerDay : ""}
                        />
                        </Form.Field>
                        </Form>
                    </Grid.Column>
                    <Grid.Column>
                        <Form>
                        <Form.Field error={errorFields.adusPerDay}>
                        <Radio
                            className="per-day-radio"
                            label={t("calendar.events.adusPerDay")}
                            name="per-day-radio-group"
                            checked={disableQtyPerDay}
                            onChange={() => setDisableQtyPerDay(true)}
                        />
                        </Form.Field>
                        <Form.Field
                        required={true}
                        error={errorFields.adusPerDay}
                        >
                        <Input
                            fluid
                            className="per-day-input"
                            name="per-day-radio-group"
                            onChange={(e) => setAdusPerDay(maskTextToExtractFloat(e.target.value))}
                            disabled={!disableQtyPerDay}
                            placeholder={adusPerDay===null ? "0.89":adusPerDay}
                            value={adusPerDay ? adusPerDay : ""}
                            error={errorFields.adusPerDay}
                        />
                        </Form.Field>
                        </Form>
                    </Grid.Column>
                    </Grid.Row>
                    {
                        !existModalData
                            &&   <Grid.Row>
                                    <Grid.Column>
                                        <Button 
                                            floated='right'
                                            onClick={()=>addGreyZone()}
                                            disabled={disableAddButton}
                                            className="add-button"
                                            >
                                                {t("simulation.addTittle")}
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            
                    }

                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button 
                        color='black' 
                        onClick={() => cancelButton()}>
                        {t("cancel")}
                    </Button>
                    <Button
                        onClick={() => sendData()}
                        positive
                        disabled={disableConfirmButton}
                        > 
                        {t("confirm")}
                    </Button>
                </Modal.Actions>
        </Modal>
    )
}

ModalGreyZone.propTypes = ({
    modalData: PropTypes.object,
    disabled: PropTypes.bool,
    changeGreyZone: PropTypes.func,
    dateRange: PropTypes.object,
    simulationGreyZone: PropTypes.array,
    changeLoadSimulationData: PropTypes.func,
    loadSimulationData: PropTypes.array
})

const mapStateToProps = (state)=>({
    simulationGreyZone: state.analytics.simulationGreyZone,
    loadSimulationData: state.analytics.loadSimulationData
})

export default connect(mapStateToProps, { changeLoadSimulationData })(ModalGreyZone)